type TData = {
  title: string;
  infoText: string;
  nameImg: string;
}[];
export const DATA: TData = [
  {
    title: "Разместите задание",
    infoText: "Опишите задачу, сроки и укажите бюджет",
    nameImg: "post-the-task",
  },
  {
    title: "Ищите исполнителей",
    infoText:
      "Получайте отклики на задание или предлагайте задачу понравившемуся самозанятому",
    nameImg: "find-selfworkers",
  },
  {
    title: "Начните сотрудничество",
    infoText:
      "Договоритесь о деталях, заключите договор и приступайте к работе",
    nameImg: "start-collaboration",
  },
];
