import React from "react";
import styled from "styled-components";

import { BannerCt } from "@components";
import { StaticImage } from "gatsby-plugin-image";

export const SectionSixCt = () => (
  <SectionBoxSd id="twoSection">
    <BannerCt
      title="Начните сотрудничество с самозанятыми"
      info="Скачайте Сам.Маркет"
      appleButtonLink="https://apps.apple.com/app/id1488581137"
      androidButtonLink="https://play.google.com/store/apps/details?id=ru.selfwork.marketplace"
      imgNode={
        <StaticImage
          src="../../images/findSelfworkers/sectionSix/SmallBannerImage.png"
          alt="Настроить оплату на сайте"
          loading="eager"
          placeholder="none"
          formats={["auto", "webp"]}
          width={552}
          height={444}
          layout="fixed"
          objectFit="contain"
        />
      }
    />
  </SectionBoxSd>
);

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
`;
