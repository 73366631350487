type TData = {
  subtitle?: string;
  title?: string;
  infoText?: string;
  nameImg?: string;
  link?: string;
}[];
export const DATA: TData = [
  {
    title: "Почему стоит работать с самозанятыми",
    infoText:
      "Все самозанятые исполнители на Сам.Маркете прошли регистрацию в ФНС — они дорожат репутацией и не исчезнут после аванса.\nВам также не придется уплачивать за них налоги и делать отчисления в ПФР: самозанятые занимаются этим самостоятельно",
    nameImg: "work-with-selfworks",
  },
  {
    title: "Как мы проверяем исполнителей",
    infoText:
      "При регистрации пользователи указывают ИНН — так мы проверяем исполнителей на наличие статуса самозанятого.\nЕсли самозанятый теряет статус, то он больше не может выполнять задания",
    nameImg: "checking-of-checking",
  },
  {
    title: "Как платить самозанятому",
    infoText:
      "Самозанятым можно платить наличными, картой, либо перечислять деньги с расчетного счета. Выберите любой удобный способ оплаты",
    nameImg: "pay-of-selfworks",
  }
];
