import React, { useMemo } from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI } from "@ui";

import { DATA } from "./constants";
import { useGetSectionThreeImgData } from "./hooks";
import { getImage } from "gatsby-plugin-image";
import { StepsBlockCt, BannerCt } from "@components";

export const SectionFiveCt = () => {
  const imgsData = useGetSectionThreeImgData();

  const contentList = useMemo(
    () =>
      DATA.map(({ title, infoText, nameImg }) => ({
        imgData: getImage(filterOfImgData(nameImg, imgsData).node),
        title,
        infoText,
      })),
    [imgsData]
  );

  return (
    <SectionSd>
      <TextBoxSd>
        <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="12px" $tac>
          Найдите подходящего исполнителя
        </TypographyUI.H2>
      </TextBoxSd>

      <StepsBlockCt contentList={contentList} />
    </SectionSd>
  );
};

const filterOfImgData = (name, data) =>
  data.allFile.edges.filter((i) => i?.node?.name === name)[0];

const SectionSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 120px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 88px;
  }
`;

const TextBoxSd = styled.div`
  max-width: 820px;
  width: 100%;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-bottom: 80px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-bottom: 40px;
  }
`;
