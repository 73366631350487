import React from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI } from "@ui";
import { IconsListCt } from "@components";
import { useGetImgData } from "./hooks";
import { getImage } from "gatsby-plugin-image";

const CONTENT_DATA = [
  {
    iconName: "Ad",
    title: "Официальное сотрудничество",
    info:
      "Все исполнители на нашей бирже  самозанятые и могут работать с юридическими лицами и ИП",
  },
  {
    iconName: "FNS",
    title: "Оплата через бухгалтерию",
    info:
      "Заплатите исполнителю с расчетного счета и не бойтесь проблем со стороны банка и ФНС",
  },
  {
    iconName: "CreditCard",
    title: "Полный пакет документов",
    info:
      "Работайте по договору ГПХ, получайте счета, чеки и другие закрывающие документы",
  },
  {
    iconName: "Russia",
    title: "Отсутствие налогов и взносов",
    info:
      "Самозанятые исполнители самостоятельно уплачивают налоги за все услуги. А еще вам не придется платить страховые взносы",
  },
  {
    iconName: "Doc",
    title: "Поиск самозанятых",
    info:
      "Самозанятые специалисты готовы сотрудничать удаленно, либо приехать к вам в офис",
  },
  {
    iconName: "Success",
    title: "Доступ к другим сервисам",
    info:
      "При подключении представители бизнеса получают бесплатный доступ к платформе выплат самозанятым",
  },
];

const filterOfImgData = (name, data) =>
  data.allFile.edges.filter((i) => i?.node?.name === name)[0];

export const SectionTwoCt = () => {
  const imgsData = useGetImgData();
  const renderData = CONTENT_DATA.map((item) => ({
    ...item,
    iconData: getImage(filterOfImgData(item.iconName, imgsData).node),
  }));

  return (
    <SectionBoxSd id="twoSection">
      <TextBoxSd>
        <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $tac>
          Как Сам.Маркет помогает компаниям
        </TypographyUI.H2>
      </TextBoxSd>
      <IconsListCt contentList={renderData} column={3} />
    </SectionBoxSd>
  );
};

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
  padding-top: 120px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-top: 80px;
  }
`;

const TextBoxSd = styled.div`
  max-width: 820px;
  width: 100%;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-bottom: 104px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-bottom: 40px;
  }
`;
