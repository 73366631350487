import React from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { ButtonUI, TypographyUI } from "@ui";

import { StaticImage } from "gatsby-plugin-image";
import { useWindowSize } from "@tools/hooks";

export const SectionThreeCt = () => {
  const { width } = useWindowSize();

  return (
    <SectionBoxSd>
      <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="80px" $tac>
        Что такое Сам.Маркет
      </TypographyUI.H2>
      <BodyBoxSd>
        <ImagesBoxSd>
          <StaticImage
            src="../../images/findSelfworkers/sectionThree/picture.png"
            alt="самозанятые"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={490}
            height={350}
          />
          <BgBoxSd>
            {width && width > LAPTOP_MOBILE_POINTER && (
              <StaticImage
                src="../../images/shared/gradiBgDsDark.png"
                alt="самозанятые"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={1107}
                height={720}
              />
            )}
            {width && width <= LAPTOP_MOBILE_POINTER && (
              <StaticImage
                src="../../images/shared/gradiBgMb.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={572}
                height={573}
                objectFit="contain"
                alt="background-img"
              />
            )}
          </BgBoxSd>
        </ImagesBoxSd>
        <ContentBoxSd>
          <TypographyUI.H3 $mb="16px">О сервисе</TypographyUI.H3>
          <InfoTetxtSd>
            Самозанятые.рф — платформа для самозанятых и тех, кто работает с
            ними. Сам.Маркет — один из сервисов платформы.
          </InfoTetxtSd>
          <InfoTetxtSd>
            Здесь физические лица, компании и индивидуальные предприниматели
            находят специалистов из разных сфер для разовых задач и долгосрочных
            проектов
          </InfoTetxtSd>
        </ContentBoxSd>
      </BodyBoxSd>
    </SectionBoxSd>
  );
};

const SectionBoxSd = styled.section`
  margin-bottom: 120px;
`;

const ImagesBoxSd = styled.div`
  position: relative;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 490px;
    height: 350px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 288px;
    height: 206px;
    margin: 0 auto;
    margin-bottom: 24px;
  }
`;

const BgBoxSd = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 1107px;
    height: 720px;
    margin-right: -300px;
    margin-top: -100px;
  }
`;

const BodyBoxSd = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    flex-direction: column;
  }
`;

const ContentBoxSd = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${LAPTOP_MOBILE_POINTER}px) {
    justify-content: center;
  }
`;

const InfoTetxtSd = styled(TypographyUI.Body1Regular)`
  & + & {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  color: var(--text-secondary);
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 480px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 100%;
  }
`;
