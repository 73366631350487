import React from "react";
import { Helmet } from "react-helmet";

import { Layout } from "@components";
import {
  SectionOfMainCt,
  SectionTwoCt,
  SectionThreeCt,
  SectionFourCt,
  SectionFiveCt,
  SectionSixCt,
} from "@templates/findSelfworkers";

const AcquiringPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Поиск самозанятого | База самозанятых граждан с данными | Сервис поиска работы самозанятых</title>
      <meta name="description" content="Поиск самозанятых исполнителей. Найти подрядчиков для бизнеса. Большая база самозанятых. Безопасно и легально, все автоматизировано" />
      <link rel="canonical" href="https://xn--80aapgyievp4gwb.xn--p1ai/find-selfworkers" />
    </Helmet>
    <Layout>
      <SectionOfMainCt />
      <SectionTwoCt />
      <SectionThreeCt />
      <SectionFourCt />
      <SectionFiveCt />
      <SectionSixCt />
    </Layout>
  </>
);

export default AcquiringPage;
