import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { BackgroundPosterCt } from "@components";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI, ButtonUI, LinkUI } from "@ui";
import { ButtonOfScrollCt } from "@components";
import { useWindowSize } from "@tools/hooks";
import {} from "gatsby";
import { useSendEvent } from "@tools/hooks";

export const SectionOfMainCt: React.FC = () => {
  const { width } = useWindowSize();
  const { clickFindPerformer } = useSendEvent();

  return (
    <SectionBoxSd>
      <BackgroundPosterCt />
      {width && width > LAPTOP_MOBILE_POINTER && (
        <BoxForImgBgSd>
          <StaticImage
            src="../../images/findSelfworkers/sectionOfMain/bg.png"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={605}
            height={616}
            layout="fixed"
            objectFit="contain"
            alt="самозанятые"
          />
        </BoxForImgBgSd>
      )}

      <TextBoxSd>
        <TextOfTitleSd swichPoint={LAPTOP_MOBILE_POINTER}>
          {"Найдите своего идеального исполнителя"}
        </TextOfTitleSd>

        <TextOfInfo swichPoint={LAPTOP_MOBILE_POINTER}>
          Сам.Маркет — место, где мы собрали самозанятых исполнителей из разных
          областей. Курьер, преподаватель английского, таргетолог – найдите
          любого специалиста за пару кликов
        </TextOfInfo>
        <LinkUI.NewWindow
          onClick={clickFindPerformer}
          href="https://market.selfwork.ru/"
        >
          <ButtonSd>К поиску</ButtonSd>
        </LinkUI.NewWindow>
      </TextBoxSd>
      <ButtonOfScrollSd id="twoSection" />
    </SectionBoxSd>
  );
};

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  padding-top: 136px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-top: 40px;
  }
`;

const BoxForImgBgSd = styled.div`
  z-index: -1;
  position: absolute;
  top: 105px;
  left: 50%;
  margin-left: 64px;
  height: 605px;
  width: 616px;
  & > * {
    height: 100%;
    width: 100%;
  }
`;

const TextBoxSd = styled.div`
  width: 100%;
  max-width: 556px;
  @media (min-width: 1205px) {
    padding-bottom: 32px;
  }
`;

const TextOfTitleSd = styled(TypographyUI.H1)`
  padding-bottom: 16px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 17px;
  }
`;

const TextOfInfo = styled(TypographyUI.Body1Regular)`
  color: var(--text-secondary);
  padding-bottom: 48px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 39px;
  }
`;

const ButtonSd = styled(ButtonUI)`
  width: 240px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
  }
`;

const ButtonOfScrollSd = styled(ButtonOfScrollCt)`
  width: 100%;
  margin-top: 119px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-top: 80px;
  }
`;
